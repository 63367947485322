import * as React from 'react';
import { Labeled, NumberInput, TextInput, useRecordContext, NumberField, ImageInput, ImageField, ReferenceInput, SelectInput } from 'react-admin';
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  SxProps,
  TableFooter,
  Typography,
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PreviewIcon from '@mui/icons-material/Preview';
import formatCurrency from 'src/utilities/formatCurrency';
import EditDialog from 'src/components/EditDialog';
import DeleteDialog from 'src/components/DeleteDialog';
import AddQuoteDialog from 'src/components/resources/shared/AddQuoteDialog';
import ApproveQuoteDialog from './dialogs/ApproveQuoteDialog';
import RejectQuoteDialog from './dialogs/RejectQuoteDialog';
import CancelQuoteDialog from './dialogs/CancelQuoteDialog';

type QuotesListProps = {
  orderStatus: string;
}

const minCellCss: SxProps = {
  width: '1%',
  whiteSpace: 'nowrap'
}

const QuotesList = (props: QuotesListProps) => {
  const item = useRecordContext();
  const departments = [...new Set(item.quotes.map((quote: any) => quote.department))];
  const quotesByDepartment = departments.map((department) => {
    const quotes = item.quotes.filter((quote: any) => quote.department === department)
    return [department, quotes]
  });
  return (
    <>
      <Grid container alignItems="center">
        <Grid item flexGrow={1}>
          <Typography variant="h6" sx={{ textTransform: 'uppercase', fontSize: '1rem', fontWeight: 600, lineHeight: "1.25em" }}>
            Repair quotes
          </Typography>
        </Grid>
        <Grid item>
          <Grid container gap={0.5}>
            {['pending_receipt', 'received', 'invoiced'].includes(props.orderStatus) && (
              <Grid item>
                <EditDialog resource="order_items" record={item} label={item.promotion_id ? "Edit Promo" : "Add Promo"} buttonVariant="outlined" description="Choose the promo code">
                  <ReferenceInput
                    label="Promotions"
                    source="promotion_id"
                    reference="promotions"
                    filter={{ type: "OrderItem", is_active: true, user_id: false, discount_type: "repair_cost" }}
                  >
                    <SelectInput optionText={(promo) => promo.code} fullWidth />
                  </ReferenceInput>
                </EditDialog>
              </Grid>
            )}
            <Grid item>
              <AddQuoteDialog />
            </Grid>
          </Grid>
        </Grid>
      </Grid>


      {item.quotes.length === 0 ? (
        <Typography variant="body2" sx={{ mt: 2, mb: 2, lineHeight: "1.25em" }}>
          No repair quotes have been added to this order yet.
        </Typography>
      ) : (
        <TableContainer sx={{ width: '100%', mt: 2, mb: 2 }}>
          <Table size="small" padding="none" aria-label="Repair services">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ pb: 1 }}>Before</TableCell>
                <TableCell align="center" sx={{ pb: 1 }}>After</TableCell>
                <TableCell sx={{ pb: 1 }}>Service</TableCell>
                <TableCell align="right" sx={{ ...minCellCss, pb: 1, px: 1 }}>Price</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotesByDepartment.map(([department, quotes], i) => (
                <React.Fragment key={i}>
                  <TableRow>
                    <TableCell valign="middle" colSpan={6} sx={{ py: 1 }}>
                      <Typography variant="subtitle2">
                        Department: <strong>{department.titleize()}</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {quotes.map((quote: any) => (
                    <TableRow key={quote.id}>
                      <TableCell sx={minCellCss}>
                        <EditDialog
                          record={quote}
                          buttonVariant="text"
                          buttonColor={quote.before_photo_url ? "primary" : "error"}
                          resource="quotes"
                          title={quote.before_photo_url ? 'Replace before photo' : 'Add before photo'}
                          label={quote.before_photo_url ? <PreviewIcon /> : <AddAPhotoIcon />}
                        >
                          {quote.before_photo_url && <div style={{ textAlign: 'center' }}>
                            <img src={quote.before_photo_url} alt={`Before ${quote.name} is performed`} style={{ width: 'auto', maxWidth: '100%', maxHeight: 500 }} />
                          </div>}
                          <ImageInput source="before_photo" label="Before photo" accept="image/*">
                            <ImageField source="before_photo" />
                          </ImageInput>
                        </EditDialog>
                      </TableCell>
                      <TableCell sx={{ ...minCellCss, pr: 1 }}>
                        <EditDialog
                          record={quote}
                          buttonVariant="text"
                          buttonColor={quote.after_photo_url ? "primary" : "error"}
                          resource="quotes"
                          title={quote.after_photo_url ? 'Replace after photo' : 'Add after photo'}
                          label={quote.after_photo_url ? <PreviewIcon /> : <AddAPhotoIcon />}
                        >
                          {quote.after_photo_url && <div style={{ textAlign: 'center' }}>
                            <img src={quote.after_photo_url} alt={`After ${quote.name} is performed`} style={{ width: 'auto', maxWidth: '100%', maxHeight: 500 }} />
                          </div>}
                          <ImageInput source="after_photo" label="after photo" accept="image/*">
                            <ImageField source="after_photo" />
                          </ImageInput>
                        </EditDialog>
                      </TableCell>
                      <TableCell component="th" scope="row" sx={{ py: 1 }}>
                        {quote.category.titleize()} - {quote.name}
                        {quote.note && (
                          <>
                            <br />
                            <small>
                              <strong>Service request: </strong>{quote.note}
                            </small>
                          </>
                        )}
                        {quote.internal_note && (
                          <>
                            <br />
                            <small>
                              <strong>Memo: </strong>{quote.internal_note}
                            </small>
                          </>
                        )}
                        {quote.performed_by?.id && (
                          <div>
                            <small>
                              Work performed by{' '}
                              <strong>
                                {quote.performed_by.old_code} {quote.performed_by.name}
                              </strong>.{' '}
                              {quote.assessed_at && (
                                <>
                                  <br />
                                  Assessed on{' '}
                                  <strong>{new Date(quote.assessed_at).toLocaleString()}</strong>
                                  {quote.assessed_by?.id && (
                                    <>
                                      {' '}by{' '}
                                      <strong>{quote.assessed_by.email}</strong>
                                    </>
                                  )}
                                </>
                              )}
                            </small>
                          </div>
                        )}
                        {quote.reason && (
                          <div>
                            <small>
                              <strong>Rejection reason: </strong>
                              {quote.reason}
                            </small>
                          </div>
                        )}
                        {quote.quality_control_memo && (
                          <div>
                            <small>
                              {/* @ts-ignore */}
                              <strong>{quote.status.titleize()}  comments: </strong>
                              {quote.quality_control_memo}
                            </small>
                          </div>
                        )}
                        {['pending_receipt', 'received', 'invoiced', 'approved'].includes(item.order.status) && (
                          <Grid container gap={1} sx={{ my: 1 }}>
                            {['pending_receipt', 'received', 'invoiced'].includes(item.order.status) && (
                              <Grid item>
                                <EditDialog record={quote} buttonVariant="outlined" buttonColor="secondary" resource="quotes" label="Edit" description="Change the note or price">
                                  <Grid container sx={{ width: '100%' }}>
                                    <Grid item xs={6}>
                                      <Labeled label="Default price">
                                        <NumberField source="default_price" locales="en-US" options={{ style: 'currency', currency: 'USD' }} />
                                      </Labeled>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Labeled label="Suggested price">
                                        <NumberField source="suggested_price" locales="en-US" options={{ style: 'currency', currency: 'USD' }} emptyText="Not available" />
                                      </Labeled>
                                    </Grid>
                                  </Grid>
                                  <NumberInput source="price" fullWidth />
                                  <TextInput source="note" fullWidth multiline />
                                  <TextInput source="internal_note" fullWidth multiline />
                                </EditDialog>
                              </Grid>
                            )}
                            {['pending_receipt', 'received', 'invoiced'].includes(item.order.status) && (
                              <Grid item>
                                <DeleteDialog record={quote} buttonVariant="outlined" resource="quotes" label="Delete quote" description="Are you sure you want to delete this quote?" />
                              </Grid>
                            )}
                            {item.order.status === 'approved' && (
                              <>
                                <Grid item>
                                  <ApproveQuoteDialog quote={quote} />
                                  <br />
                                </Grid>
                                <Grid item>
                                  <RejectQuoteDialog quote={quote} />
                                  <br />
                                </Grid>
                                <Grid item>
                                  <CancelQuoteDialog quote={quote} />
                                  <br />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        )}
                      </TableCell>
                      <TableCell align="right" sx={{ py: 1, px: 1 }}>
                        {quote.promotion_discount ? (
                          <>
                            <span style={{ textDecoration: 'line-through', color: 'gray' }}>
                              {formatCurrency(quote.price)}
                            </span>{' '}
                            <strong>{formatCurrency(quote.subtotal)}</strong>
                          </>
                        ) : (
                          <strong>{formatCurrency(quote.subtotal)}</strong>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {item.promotion_discount && (
                  <>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell component="th" scope="row" sx={{ py: 1 }}>
                      Promotion Discount ({item.promotion.code})
                    </TableCell>
                    <TableCell align="right" sx={{ py: 1, px: 1 }}>
                      {formatCurrency(item.promotion_discount)}
                    </TableCell>
                  </>
                )}
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell component="th" scope="row" sx={{ py: 1 }}>
                  Subtotal
                </TableCell>
                <TableCell align="right" sx={{ py: 1, px: 1 }}>
                  {formatCurrency(item.subtotal)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default QuotesList;
