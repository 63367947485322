import React, { useRef } from 'react'
import { Link, Title } from 'react-admin'
import { Box, Button, Card, CardContent, Grid, SxProps, Theme } from '@mui/material'
import { WorkOutScreenProvider, useWorkOutContext } from 'src/providers/workOutScreen'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import formatCurrency from 'src/utilities/formatCurrency';

type Props = {
  storefrontId?: string
}

function TicketHistory(props: Props) {
  const { orderItems } = useWorkOutContext()
  return (
    <Card sx={{ height: '100%', overflowY: 'hidden' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <TableContainer component={Paper} sx={{ maxHeight: '100%' }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Work Out</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderItems.length > 0 ? (
                <>{orderItems.map((orderItem) => (
                  <TableRow key={orderItem.id}>
                    <TableCell>{orderItem && orderItem.code}</TableCell>
                    <TableCell>
                      {new Date(orderItem.plant_out_at).toLocaleString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })}
                    </TableCell>
                  </TableRow>
                ))}</>
              ) : (
                <TableRow>
                  <TableCell colSpan={2}>Scanned items will appear here as they are processed.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
}

// TODO! Should call store_in when store_in_at is null and is rackable or already racked.

// dupe
const cardSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  marginY: '1em',
  paddingX: '1em'
}

// dupe
const messageBoxStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  textAlign: 'center',
  height: '100%',
  width: '100%',
  padding: '2em'
}

// dupe
const buttonStyle: React.CSSProperties = {
  fontWeight: 600,
  textDecoration: 'none',
  borderWidth: 2,
  padding: '0.5em 1em',
  borderStyle: 'solid'
}

const ScanTicketCard = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    code, display, ticketQuery, setTicketQuery,
    orderItem, setOrderItem, searchOrderItem
  } = useWorkOutContext()
  let timeout: ReturnType<typeof setTimeout>

  const handleChange = () => {
    if (inputRef.current) {
      clearTimeout(timeout)
      timeout = setTimeout(async () => {
        try {
          await searchOrderItem(inputRef.current!.value)
          // TODO: call order_items/:id/plant_in
          // TODO: append order item details to queue on success.
        } catch (err) {
          setOrderItem(undefined)
          if (inputRef.current) inputRef.current.value = ''
        }
      }, 500)
    }
  }

  const reset = () => {
    if (inputRef.current) inputRef.current.value = ''
    setTimeout(() => {
      setOrderItem(undefined)
      setTicketQuery('')
    }, 500)
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ ...cardSx, backgroundColor: display.backgroundColor, color: `${display.textColor} !important` }}>
        <input
          type="text"
          ref={inputRef}
          onChange={handleChange}
          autoFocus={true}
          onBlur={(e) => {
            e.preventDefault()
            const target = e.currentTarget as HTMLInputElement
            setTimeout(function () { target.focus() }, 5)
          }}
          style={{ opacity: 0.0, position: 'absolute', left: '-9999px' }}
        />
        {code === 'initial' ? (
          <div style={messageBoxStyle}>
            <span style={{ fontSize: '4rem', fontWeight: 600 }}>WORK OUT</span>
            <span style={{ fontSize: '3rem', fontWeight: 600 }}>Start scanning ticket barcodes</span>
          </div>
        ) : code === 'not_found' ? (
          <div style={{ textAlign: 'center' }}>
            <h1 style={{ fontSize: '3rem', marginBottom: '0.2em' }}>
              <>Could not find: {ticketQuery}</>
            </h1>
            <Button variant="contained" color="secondary" size="large" onClick={reset}>
              Try Again
            </Button>
          </div>
        ) : (
          <div style={messageBoxStyle}>
            <span style={{ fontSize: '4rem', fontWeight: 600 }}>PLANT OUT</span>
            <span style={{ fontSize: '3rem', fontWeight: 600 }}>{orderItem.code}</span>
            <span style={{ fontSize: '3rem', fontWeight: 600 }}>{formatCurrency(orderItem.subtotal)}</span>
            <span style={{ fontSize: '3rem', fontWeight: 600 }}>{display.message}</span>
            <Link to={`/orders/${orderItem.order_id}/show`} target="_blank" style={buttonStyle}>
              Review Order
            </Link>
            <Button variant="contained" color="secondary" size="large" onClick={reset}>
              Next Ticket
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

function WorkOutPage() {
  return (
    <WorkOutScreenProvider>
      <Title title="Work Out" />
      <Grid container sx={{ height: 'calc(100vh - 96px)' }}>
        <Grid item xs={4} height="100%">
          <TicketHistory />
        </Grid>
        <Grid item xs={8} maxHeight="100%">
          <ScanTicketCard />
        </Grid>
      </Grid>
    </WorkOutScreenProvider>
  )
}

export default WorkOutPage
