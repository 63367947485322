import React from 'react'
import {
  List as AdminList, Datagrid, SearchInput, Filter, ListProps, TextField, SelectInput
} from 'react-admin';
import ItemCodeField from 'src/components/resources/order_items/ItemCodeField';
import StatusField from 'src/components/resources/shared/StatusField';

const statuses = [
  'pending_receipt',
  'received',
  'invoiced',
  'approved',
  'repaired',
  'pending_return',
  'returned'
]

const List = (props: ListProps) => (
  <AdminList {...props}
    title="Quality Control"
    actions={false}
    filter={{ order_id: true }}
    filters={(
      <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        {/* @ts-ignore */}
        <SelectInput source="order.status" choices={statuses.map((status) => ({ id: status, name: status.titleize() }))} emptyText="Any" alwaysOn />
        {/* <ButtonInput source="is_rush" alwaysOn /> */}
      </Filter>
    )}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <ItemCodeField label="Item" />
      <StatusField label="Status" source="order.status" />
      <TextField source="formatted_description" />
    </Datagrid>
  </AdminList>
);

export default List;
