import React, { createContext, useContext, useMemo, useState, ReactNode, useEffect } from 'react'
import dataProvider from './data'
import { useNotify } from 'react-admin'
import env from 'src/env'

interface WorkInContextType {
  code: Code
  display: Display
  ticketQuery: string
  setTicketQuery: (query: string) => void
  storefrontId?: string
  setStorefrontId: (query: string) => void
  orderItem?: any | undefined
  setOrderItem: React.Dispatch<React.SetStateAction<Record<string, unknown> | undefined>>
  searchOrderItem: (debouncedValue: string) => Promise<void>
  orderItems: any[]
  setOrderItems: React.Dispatch<React.SetStateAction<any[]>>
}

const WorkInContext = createContext<WorkInContextType | undefined>(undefined)

export type Code = 'not_ready' | 'ready' | 'processed' | 'invalid' | 'not_found' | 'initial'

export type Display = {
  textColor: 'black' | 'white'
  backgroundColor: 'red' | 'green' | 'gray' | 'transparent'
  message: string
}

const statusToCode = {
  'pending_receipt': 'not_ready',
  'received': 'ready',
  'invoiced': 'ready',
  'approved': 'ready',
  'repaired': 'invalid',
  'pending_return': 'invalid',
  'returned': 'invalid',
  'canceled': 'invalid'
}

const getDisplay = (code: Code): Display => {
  switch (code) {
    case 'not_ready':
      return {
        textColor: 'white',
        backgroundColor: 'red',
        message: 'Item not ready for plant-in.'
      }
    case 'ready':
      return {
        textColor: 'black',
        backgroundColor: 'green',
        message: 'Item will be processed plant-in.'
      }
    case 'processed':
      return {
        textColor: 'black',
        backgroundColor: 'green',
        message: 'Plant-in processed.'
      }
    case 'invalid':
      return {
        textColor: 'black',
        backgroundColor: 'gray',
        message: 'In-plant processing not applicable for this order.'
      }
    case 'not_found':
      return {
        textColor: 'black',
        backgroundColor: 'gray',
        message: 'Item not found.'
      }
    default:
      return {
        textColor: 'black',
        backgroundColor: 'transparent',
        message: 'Start scanning ticket barcodes'
      }
  }
}

export const WorkInScreenProvider = ({ children }: { children: ReactNode }) => {
  const notify = useNotify()
  const token = localStorage.getItem(env.TOKEN_KEY)
  const [storefrontId, setStorefrontId] = useState<string>()
  const [ticketQuery, setTicketQuery] = useState('')
  const [orderItem, setOrderItem] = useState<any>()
  const [orderItems, setOrderItems] = useState<any[]>([])

  const appendOrderItem = (orderItem: any) => {
    setOrderItems(prevOrderItems => [orderItem, ...prevOrderItems])
  }

  const code: Code = useMemo(() => {
    let code = (statusToCode[orderItem?.order.status as keyof typeof statusToCode]) as Code
    if (orderItem) {
      if (orderItem.plant_in_at !== null) code = 'processed'
    } else if (ticketQuery !== '') {
      code = 'not_found'
    } else {
      code = 'initial'
    }
    return code as Code
  }, [orderItem, ticketQuery])

  const display: Display = useMemo(() => getDisplay(code), [code])

  const searchOrderItem = async (debouncedValue: string) => {
    const { data } = await dataProvider.getList('order_items', {
      filter: { q: `"${debouncedValue}"` },
      sort: { field: 'created_at', order: 'ASC' },
      pagination: { page: 1, perPage: 1 },
    })
    setTicketQuery(debouncedValue)
    if (data.length === 0) {
      setOrderItem(undefined)
    } else {
      const orderItem = data.find((item: any) => item.code === debouncedValue)
      if (orderItem) {
        setOrderItem({ ...orderItem })
      } else {
        setOrderItem(undefined)
      }
    }
  }

  const triggerWorkIn = async () => {
    try {
      const response = await fetch(`${env.API_URL}/rest/order_items/${orderItem.id}/plant_in`, {
        method: 'POST',
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      })
      if (response.status >= 400) {
        const data = await response.json()
        const errArray = Object.entries(data.errors)[0]
        // @ts-ignore
        const error = errArray[0].titleize() + ' ' + errArray[1][0]
        notify(`Error: ${error}.`, { type: 'warning' })
      } else {
        const { data } = await dataProvider.getOne('order_items', { id: orderItem.id })
        setOrderItem(data)
        notify('Customer notified for pickup')
      }
    } catch (error) {
      notify('Server error. Contact the developer', { type: 'error' })
    }
  }

  useEffect(() => {
    (async () => {
      if (code === 'ready') await triggerWorkIn()
      if (code === 'processed') await appendOrderItem(orderItem)
    })()
  }, [code])

  return (
    <WorkInContext.Provider value={{
      code,
      display,
      ticketQuery,
      setTicketQuery,
      storefrontId,
      setStorefrontId,
      orderItem,
      setOrderItem,
      searchOrderItem,
      orderItems,
      setOrderItems
    }}>
      {children}
    </WorkInContext.Provider>
  )
}

export const useWorkInContext = () => {
  const context = useContext(WorkInContext)
  if (!context) {
    throw new Error('useWorkInContext must be used within a WorkInScreenProvider')
  }
  return context
}
