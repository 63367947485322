import * as React from 'react';
import {
  Labeled,
  RecordContextProvider,
  ImageInput,
  ImageField,
} from 'react-admin';
import {
  Grid,
  Typography,
  Divider,
  Button
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PreviewIcon from '@mui/icons-material/Preview';
import PrintIcon from '@mui/icons-material/Print';
import EditDialog from 'src/components/EditDialog';
import formatCurrency from 'src/utilities/formatCurrency';
import QuotesList from '../orders/QuotesList';
import ItemMemoDialog from '../orders/dialogs/ItemMemoDialog';
import ItemDescriptionDialog from '../shared/ItemDescriptionDialog';
import InquiriesList from './InquiriesList';
import env from 'src/env';

function formatDatetime(dateString: string) {
  const date = new Date(dateString)
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit'
  });
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  return `${formattedDate} ${formattedTime}`
}

const labelStyle = { '& span': { fontWeight: 900 }, '& p': { marginY: '0.25em' } }

const ItemDetails = (props: { item: any, order: any }) => {
  const { item, order } = props
  // check if either is set or don't render at all.
  const plantDate = !!item.plant_out_at ? item.plant_out_at : item.plant_in_at
  return (
    <RecordContextProvider value={item}>
      <Grid container>
        <Grid item flexGrow={1}>
          <Typography variant="h6">
            <strong>{item.code}</strong> &ndash; {item.repairable_name}
          </Typography>
        </Grid>
        <Grid item flexShrink={1} alignItems="end">
          <Button
            size="small"
            color="primary"
            variant="outlined"
            href={`${env.API_URL}/rest/order_items/${item.id}.png`}
            target="_blank"
          >
            <PrintIcon fontSize="small" sx={{ px: '0 !important' }} />&nbsp;Ticket
          </Button>
        </Grid>
        {item.plant_out_at ? (
          <Grid item flexShrink={1} alignItems="end" paddingLeft="0.5em" alignContent="center">
            <Typography variant="subtitle2" marginBottom={0.5}>
              Plant Out @ {formatDatetime(item.plant_out_at)}
            </Typography>
          </Grid>
        ) : item.plant_in_at ? (
          <Grid item flexShrink={1} alignItems="end" paddingLeft="0.5em" alignContent="center">
            <Typography variant="subtitle2" marginBottom={0.5}>
              Plant In @ {formatDatetime(item.plant_in_at)}
            </Typography>
          </Grid>
        ) : <></>}
      </Grid>
      <Grid container>
        <Grid item flexGrow={1}>
          <Labeled label="Color" sx={labelStyle}>
            <Typography variant="subtitle2" marginBottom={1}>
              {item.color}
            </Typography>
          </Labeled>
        </Grid>
        <Grid item flexGrow={1}>
          <Labeled label="Material" sx={labelStyle}>
            <Typography variant="subtitle2" marginBottom={1}>
              {item.material}
            </Typography>
          </Labeled>
        </Grid>
        <Grid item flexGrow={1}>
          <Labeled label="Brand" sx={labelStyle}>
            <Typography variant="subtitle2" marginBottom={1}>
              {item.brand}
            </Typography>
          </Labeled>
        </Grid>
      </Grid>
      <Grid item flexGrow={1}>
        <Labeled label="Customer Photos" sx={labelStyle}>
          <Typography variant="subtitle2" marginBottom={1}>
            {item.customer_photo_urls.length > 0 ? item.customer_photo_urls.map((photoUrl: string) => (
              <li>
                <a href={photoUrl} target="_blank" />
              </li>
            )) : "No photos"}
          </Typography>
        </Labeled>
      </Grid>
      <Labeled label="Customer Description" sx={labelStyle}>
        <Typography variant="subtitle2" marginBottom={1}>
          {item.description ? item.description : 'None provided.'}
        </Typography>
      </Labeled>
      <br />
      <Labeled label="Estimated Item Value" sx={labelStyle}>
        <Typography variant="subtitle2" marginBottom={1}>
          {item.estimated_value ? formatCurrency(item.estimated_value) : 'None provided.'}
        </Typography>
      </Labeled>
      <br />
      <Labeled label="Memo" sx={labelStyle}>
        <Typography variant="subtitle2">
          {item.internal_note}
        </Typography>
      </Labeled>
      <br />
      <ItemMemoDialog />
      <br />
      <Labeled label="Description" sx={{ mt: 2, mb: 1, ...labelStyle }}>
        <Typography variant="subtitle2">
          <div dangerouslySetInnerHTML={{ __html: item.customer_note }} />
        </Typography>
      </Labeled>
      <br />
      <ItemDescriptionDialog />
      <Divider sx={{ my: 3, backgroundColor: 'black' }} />
      <Typography variant="h6" sx={{ textTransform: 'uppercase', fontSize: '1rem', fontWeight: 600, lineHeight: '1.25em', mb: 2 }}>
        Customer inquiries
      </Typography>
      <InquiriesList />
      <QuotesList orderStatus={order.status} />
    </RecordContextProvider>
  )
}

export default ItemDetails;
