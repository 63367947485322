import * as React from 'react';
import {
  Identifier,
  useGetList,
  useRecordContext
} from 'react-admin';
import {
  Container,
  Divider
} from '@mui/material';
import ItemDetails from '../order_items/ItemDetails';

type ItemsListProps = {
  orderStatus: string;
  orderId: Identifier;
}

const ItemsList = (props: ItemsListProps) => {
  const order = useRecordContext();
  const { data = [], isLoading } = useGetList('order_items', {
    filter: {
      order_id: props.orderId
    },
    pagination: {
      page: 1,
      perPage: 100
    }
  });
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Container disableGutters sx={{ mb: 2 }}>
      {data.map((item, i) => (
        <React.Fragment key={i}>
          <ItemDetails key={i} order={order} item={item} />
          {i < data.length - 1 && <Divider sx={{ my: 3, backgroundColor: 'black' }} />}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default ItemsList;
