import * as React from 'react'
import {
  useRecordContext,
  useRefresh,
  useNotify
} from 'react-admin'
import { Button } from '@mui/material'
import env from 'src/env'
import RefreshIcon from '@mui/icons-material/Refresh'

type SyncButtonProps = {}

const SyncButton = (props: SyncButtonProps) => {
  const token = localStorage.getItem(env.TOKEN_KEY)
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const [isSubmitting, setSubmitting] = React.useState(false)
  const handleClick = async () => {
    setSubmitting(true)
    try {
      const response = await fetch(`${env.API_URL}/rest/charges/${record.id}/sync`, {
        method: 'POST',
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.status >= 400) {
        const data = await response.json()
        const errArray = Object.entries(data.errors)[0]
        // @ts-ignore
        const error = errArray[0].titleize() + ' ' + errArray[1][0]
        notify(`Error: ${error}.`, { type: 'warning' })
      } else {
        notify('Successfully synced payment with Square')
      }
    } catch (error) {
      notify('Server error. Contact the developer', { type: 'error' })
      // TODO: log error in sentry
    } finally {
      setSubmitting(false)
      refresh()
    }
  }
  return (
    <Button
      size="small"
      color="primary"
      variant="text"
      title="Refresh Transaction Status"
      onClick={handleClick}
      disabled={isSubmitting}
    >
      <RefreshIcon fontSize="small" />
    </Button>
  )
}

export default SyncButton
